import { QRCode } from "antd";
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";

const QR = ({ setLogin, login, setAssent, image, setImage, providerId }) => {
  const [passed, setPassed] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams();
    params.append(
      "Authorization",
      "Basic cHJvdmlkZXI6c1ZvQkp0YzdXSXJ1TkJmZFQ3MURIUmdkUlA5RTlwTTNRelR1YlJSZ3BucDVnOUdlUkJPQ2tET0hndTdMa2FqRA=="
    );
    params.append("grant_type", "CLIENT_CREDENTIALS");
    params.append("username", process.env.REACT_APP_USERNAME);
    params.append("password", process.env.REACT_APP_PASSWORD);
    axios
      .post(`https://spw-backend.dotsyntax.gr/api/v1.0/auth/token`, params)
      .then((res) => {
        res.data && res.data.token && setToken(res.data.token);
        console.log(res.data.token, "accesstoken");
        res.data &&
          res.data.token &&
          axios
            .post(
              `https://spw-backend.dotsyntax.gr/api/v1.0/sessions`,
              { providerId },
              { headers: { Authorization: `Bearer ${res.data.token}` } }
            )
            .then((res) => {
              setImage(res.data.sessionToken);
            })
            .catch((err) => {
              console.log({ err });
            });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [login]);

  useEffect(() => {
    if (image && !passed) {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_URL}/sessions?id=` + image
      );

      ws.onopen = () => {
        console.log("WebSocket connection opened");
        setPassed(true);
      };

      ws.onmessage = (e) => {
        console.log("Received message:", e.data);
        const data = JSON.parse(e.data);
        token &&
          data.jwt &&
          axios
            .post(
              `${process.env.REACT_APP_PROVIDER_URL}/smart-pass-tokens`,
              { jwt: data.jwt },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => console.log({ res }))
            .catch((err) => console.log({ err }));
        data.type === "pairing" && data.code === 1 && setLogin(false);
        data.type === "pairing" && data.code === 1 && setAssent(true);
        data.type === "pairing" && data.code === 1 && setImage(null);
        data.type === "pairing" && data.code === 1 && setPassed(false);
      };

      ws.onerror = (e) => {
        console.error("WebSocket error:", e.message);
      };

      ws.onclose = (e) => {
        console.log("WebSocket connection closed:", e);
      };

      // Clean up the WebSocket connection on component unmount
      return () => {
        ws.close();
      };
    }
  }, [image]);

  console.log({ image });

  return (
    <div
      style={{
        backgroundColor: "#1D1D1D",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 550,
      }}
    >
      <img
        style={{ width: 30, paddingTop: 50 }}
        src={require("./images/recycle.png")}
      />
      <span>Pair with SmartPass</span>
      <br />
      <br />
      <br />
      <div style={{ backgroundColor: "#fff", height: 160 }}>
        {image && (
          <QRCode
            style={{}}
            value={JSON.stringify({ ...jwtDecode(image), token: image })}
          />
        )}
      </div>
      <span style={{ marginTop: 10, fontSize: 10 }}>
        Scan this Code in{" "}
        <span style={{ color: "#1CF2BD" }}>the SmartPass Mobile App</span>
      </span>
    </div>
  );
};

export default QR;
