import "./App.css";

import { useEffect, useState } from "react";
import { Button, Modal, Row, Layout, Space } from "antd";
import First from "./first";
import QR from "./QR";
import Assent from "./Assent";
import Allow from "./Allow";
import { SearchOutlined } from "@ant-design/icons";

const { Header, Footer, Content } = Layout;

function App() {
  const [open, setOpen] = useState(false);
  const [first, setFirst] = useState(true);
  const [login, setLogin] = useState(false);
  const [assent, setAssent] = useState(false);
  const [assent2, setAssent2] = useState(false);
  const [image, setImage] = useState(null);
  const [validUser, setValidUser] = useState(false);
  const [providerId, setProviderId] = useState(0);

  const [param, setParam] = useState(1);

  const [page, setPage] = useState("GAMING");

  /* Provider IDs
      1: Gaming
      2: Betting
      3: Gambling
      4: E-Shop
      5: Alcohol
      6: Gov
  */

  useEffect(() => {
    if (window.location.pathname === "/gaming") {
      setParam(1);
      setPage("GAMING");
    } else if (window.location.pathname === "/betting") {
      setParam(2);
      setPage("BETTING");
    } else if (window.location.pathname === "/gambling") {
      setParam(3);
      setPage("GAMBLING");
    } else if (window.location.pathname === "/shop") {
      setParam(4);
      setPage("SHOP");
    } else if (window.location.pathname === "/alcohol") {
      setParam(5);
      setPage("ALCOHOL");
    } else if (window.location.pathname === "/gov") {
      setParam(6);
      setPage("GOV");
    } else {
      setParam(3);
      setPage("GAMBLING");
    }
  }, []);

  console.log({ param });

  const navLinks =
    page === "GAMING"
      ? ["Home", "About", "Games", "Results", "The Winners", "Contact"]
      : page === "BETTING"
      ? [
          "Home",
          "About",
          "Live Bets",
          "Casino",
          "Offers",
          "Virtual Sports",
          "Contact",
        ]
      : page === "GAMBLING"
      ? [
          "Home",
          "About",
          "Live Bets",
          "Casino",
          "Offers",
          "Virtual Sports",
          "Contact",
        ]
      : page === "SHOP"
      ? ["Σαλόνι", "Τραπεζαρία", "Κουζίνα", "Κρεβατοκάμαρα", "Μπάνιο"]
      : page === "ALCOHOL"
      ? ["Κόκκινα", "Λευκά", "Ροζέ", "Συλλογές", "Δώρα"]
      : page === "GOV"
      ? ["Δικαιοσύνη", "Εργασία", "Φορολογία", "Ασφάλιση"]
      : [];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
      <Layout>
        <Header
          style={{
            minHeight: 100,
            lineHeight: "120px",
            color: "#fff",
            backgroundColor:
              page === "GAMING"
                ? "#183F63"
                : page === "BETTING"
                ? "#2F6043"
                : page === "GAMBLING"
                ? "#222222"
                : page === "SHOP"
                ? "#194C61"
                : page === "ALCOHOL"
                ? "#9E7E40"
                : page === "GOV"
                ? "#1A354E"
                : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row
            style={{
              maxWidth: 1100,
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {page === "GAMING" ? (
                <svg
                  width="89"
                  height="73"
                  viewBox="0 0 129 73"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_839_36836)">
                    <path
                      d="M46.2572 24.2632C41.4768 21.9491 36.6965 20.6868 32.4082 20.6868C26.7139 20.6868 23.1286 23.8425 23.1286 28.611C23.1286 33.1691 26.1515 35.4131 32.0567 35.4131V26.6475H56.8022V56.8012C51.6 57.1518 39.5787 57.6427 36.4856 57.6427C24.1831 57.6427 14.6223 55.5389 8.57657 49.5082C2.81199 43.6878 0 36.6052 0 28.611C0 14.7262 9.06867 0 31.0725 0C40.2114 0 50.6158 2.94525 58.2082 7.50336L46.2572 24.2632Z"
                      fill="white"
                    />
                    <path
                      d="M113.323 73H98.1382L90.7567 54.6273L83.9376 73H68.8232L52.4434 26.6475H72.5491L77.8919 41.7944L83.5861 26.6475H97.857L103.762 41.7944L108.894 26.6475H129L113.323 73Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_839_36836">
                      <rect width="129" height="73" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : page === "GAMBLING" ? (
                <img
                  alt="logo"
                  src={require("./images/winchip.png")}
                  width={150}
                />
              ) : page === "BETTING" ? (
                <img
                  alt="logo"
                  src={require("./images/betOnly.png")}
                  width={150}
                />
              ) : page === "SHOP" ? (
                <img
                  alt="logo"
                  src={require("./images/SWEETHOME.png")}
                  width={150}
                />
              ) : page === "ALCOHOL" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="logo"
                    src={require("./images/grape.png")}
                    height={35}
                  />
                  <img
                    alt="logo"
                    src={require("./images/CASA DI VINO.png")}
                    width={150}
                  />
                </div>
              ) : page === "GOV" ? (
                <h2>GoverNet</h2>
              ) : null}
              <div>
                {navLinks.map((text) => (
                  <span style={{ color: "#fff", marginLeft: 22, fontSize: 15 }}>
                    {text}
                  </span>
                ))}
              </div>
            </div>

            <div>
              {!validUser && (
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 0,
                    color: "#fff",
                  }}
                  onClick={() => {
                    setProviderId(param);
                    setOpen(true);
                  }}
                >
                  LOG IN
                </Button>
              )}
              {!validUser && (
                <Button
                  style={{
                    marginLeft: 20,
                    backgroundColor: "transparent",
                    borderRadius: 0,
                    color: "#fff",
                  }}
                  onClick={() => {
                    setProviderId(param);
                    setOpen(true);
                  }}
                >
                  SIGN IN
                </Button>
              )}
              {validUser && (
                <span style={{ color: "#fff", fontsize: 45 }}>Welcome</span>
              )}
              <SearchOutlined
                style={{ marginLeft: 20, color: "#fff", fontSize: 24 }}
              />
            </div>
          </Row>
        </Header>
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 242px",
          }}
        >
          <img
            style={{ maxWidth: "1100px" }}
            alt="page-intro"
            src={require(page === "GAMING"
              ? "./images/aaaa.png"
              : page === "BETTING"
              ? "./images/bets-intro.png"
              : page === "GAMBLING"
              ? "./images/win-intro.png"
              : page === "SHOP"
              ? "./images/shop-intro.png"
              : page === "ALCOHOL"
              ? "./images/alcohol-intro.png"
              : page === "GOV"
              ? "./images/gov-intro-1.png"
              : "./images/win-intro.png")}
          />

          <br />
          <br />

          <img
            style={{ maxWidth: "1100px" }}
            alt="page-main"
            src={require(page === "GAMING"
              ? "./images/99.png"
              : page === "BETTING"
              ? "./images/bets-main.png"
              : page === "GAMBLING"
              ? "./images/win-main.png"
              : page === "SHOP"
              ? "./images/shop-main.png"
              : page === "ALCOHOL"
              ? "./images/alcohol-main.png"
              : page === "GOV"
              ? "./images/gov-main.png"
              : "./images/win-main.png")}
          />

          <br />
          <br />
          <br />
        </Content>
        <Footer
          style={{
            minHeight: 120,
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            backgroundColor:
              page === "GAMING"
                ? "#183F63"
                : page === "BETTING"
                ? "#2F6043"
                : page === "GAMBLING"
                ? "#222222"
                : page === "SHOP"
                ? "#194C61"
                : page === "ALCOHOL"
                ? "#9E7E40"
                : page === "GOV"
                ? "#1A354E"
                : "",
          }}
        >
          <img
            style={{ width: "100vw" }}
            alt="page-footer"
            src={require(page === "GAMING"
              ? "./images/gw-footer.png"
              : page === "BETTING"
              ? "./images/bets-footer.png"
              : page === "GAMBLING"
              ? "./images/win-footer.png"
              : page === "SHOP"
              ? "./images/shop-footer.png"
              : page === "ALCOHOL"
              ? "./images/alcohol-footer.png"
              : page === "GOV"
              ? "./images/gov-footer.png"
              : "./images/win-footer.png")}
          />
        </Footer>
      </Layout>
      <Modal
        closable={false}
        onCancel={() => {
          setOpen(false);
          setFirst(true);
          setLogin(false);
          setAssent(false);
          setAssent2(false);
        }}
        onOk={() => setOpen(false)}
        footer={null}
        open={open}
      >
        {first && <First setFirst={setFirst} setLogin={setLogin} />}
        {login && (
          <QR
            image={image}
            login={login}
            setImage={setImage}
            setLogin={setLogin}
            setAssent={setAssent}
            providerId={providerId}
          />
        )}
        {assent && <Assent setAssent={setAssent} setAssent2={setAssent2} />}
        {assent2 && (
          <Allow
            setValidUser={setValidUser}
            setOpen={setOpen}
            setFirst={setFirst}
            setAssent2={setAssent2}
          />
        )}
      </Modal>
    </Space>
  );
}

export default App;
