import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Switch } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const First = ({ setFirst, setLogin }) => {
  return (
    <div>
      <Form
        name="normal_login"
        className="login-form"
        style={{ padding: "0px 5vw" }}
        initialValues={{
          remember: true,
        }}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}>
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            Remember me
          </Form.Item>

          <a className="login-form-forgot" href="" style={{ float: "right" }}>
            <Switch />
          </a>
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              width: "100%",
              backgroundColor: "#EAC960",
              borderRadius: 25,
              color: "black",
            }}
            type="primary"
            htmlType="submit"
            className="login-form-button">
            Log in
          </Button>
          <br />
          <br />
          <a
            className="login-form-forgot"
            style={{ marginLeft: "4vw" }}
            href="">
            Forgot password
          </a>
        </Form.Item>
      </Form>
      <Divider>Or</Divider>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: "#EAC960",
            color: "black",
            borderRadius: 25,
            width: "15vw",
          }}
          onClick={() => {
            setFirst(false);
            setLogin(true);
          }}>
          Register with SmartPass
        </Button>
      </div>
      {/* <img style={{ marginLeft: -20, width: 500 }} src={require('./images/img2.png')} /> */}
    </div>
  );
};
export default First;
