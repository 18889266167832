import { Col, Divider, Row } from "antd";
import React from "react";

const Assent = ({ setAssent, setAssent2 }) => {
  return (
    <div
      style={{
        height: 600,
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#1D1D1D",
      }}
    >
      <img
        style={{ width: 250, marginTop: 50, marginBottom: 50 }}
        src={require("./images/SmartPass.png")}
      />
      <Row style={{ margin: "0px 30px" }}>
        <Col span={11} style={{ display: "flex" }}>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/controlData.png")}
          />
          <div style={{ marginLeft: 12 }}>
            <span style={{ fontSize: 11, fontWeight: 700 }}>
              Control & Monetize Data
            </span>
            <br />
            <span style={{ fontSize: 11 }}>
              <span style={{ color: "#1CF2BD" }}>
                Manage access precisely and get paid{" "}
              </span>
              when you share your data
            </span>
          </div>
        </Col>
        <Col span={2} />
        <Col span={11} style={{ display: "flex" }}>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/Anon.png")}
          />
          <div style={{ marginLeft: 12 }}>
            <span style={{ fontSize: 11, fontWeight: 700 }}>Go Anonymous</span>
            <br />
            <span style={{ fontSize: 11 }}>
              Assent primarily verifies your data to operators but{" "}
              <span style={{ color: "#1CF2BD" }}>does not share it</span>
            </span>
          </div>
        </Col>
      </Row>
      <br />
      <Row style={{ margin: "0px 30px" }}>
        <Col span={11} style={{ display: "flex" }}>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/regFriend.png")}
          />
          <div style={{ marginLeft: 12 }}>
            <span style={{ fontSize: 11, fontWeight: 700 }}>
              Regulator Friendly
            </span>
            <br />
            <span style={{ fontSize: 11 }}>
              Designed to integrate with regulated actitivies{" "}
              <span style={{ color: "#1CF2BD" }}>
                such as gambling or betting
              </span>
            </span>
          </div>
        </Col>
        <Col span={2} />
        <Col span={11} style={{ display: "flex" }}>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/fastPayments.png")}
          />
          <div style={{ marginLeft: 12 }}>
            <span style={{ fontSize: 11, fontWeight: 700 }}>
              Fast & Private Payments
            </span>
            <br />
            <span style={{ fontSize: 11 }}>
              Wallet funds can be used for{" "}
              <span style={{ color: "#1CF2BD" }}>
                secure private payments and withdrawals
              </span>
            </span>
          </div>
        </Col>
      </Row>
      <br />

      <span
        style={{
          color: "#1CF2BD",
          marginTop: 20,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          setAssent(false);
          setAssent2(true);
        }}
      >
        Continue
      </span>
    </div>
  );
};

export default Assent;
