import { Button, Row } from "antd";
import React from "react";

const Allow = ({ setOpen, setValidUser, setFirst, setAssent2 }) => {
  return (
    <div
      style={{
        height: 660,
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1D1D1D",
      }}
    >
      <br />
      <br />
      <img style={{ width: 250 }} src={require("./images/box.png")} />
      <span
        style={{ marginTop: -80, marginLeft: 20, fontSize: 18, width: 140 }}
      >
        {"Registered with GW SmartPass"}
      </span>
      <svg
        width="129"
        height="73"
        viewBox="0 0 129 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginTop: -120, width: 40, marginLeft: -170 }}
      >
        <g clip-path="url(#clip0_839_36836)">
          <path
            d="M46.2572 24.2632C41.4768 21.9491 36.6965 20.6868 32.4082 20.6868C26.7139 20.6868 23.1286 23.8425 23.1286 28.611C23.1286 33.1691 26.1515 35.4131 32.0567 35.4131V26.6475H56.8022V56.8012C51.6 57.1518 39.5787 57.6427 36.4856 57.6427C24.1831 57.6427 14.6223 55.5389 8.57657 49.5082C2.81199 43.6878 0 36.6052 0 28.611C0 14.7262 9.06867 0 31.0725 0C40.2114 0 50.6158 2.94525 58.2082 7.50336L46.2572 24.2632Z"
            fill="white"
          />
          <path
            d="M113.323 73H98.1382L90.7567 54.6273L83.9376 73H68.8232L52.4434 26.6475H72.5491L77.8919 41.7944L83.5861 26.6475H97.857L103.762 41.7944L108.894 26.6475H129L113.323 73Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_839_36836">
            <rect width="129" height="73" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Row style={{ marginTop: -50, width: 40, marginLeft: 170 }}>
        <img
          style={{ width: 20, height: 20 }}
          src={require("./images/location.png")}
        />
        <img
          style={{ width: 20, height: 20 }}
          src={require("./images/Age.png")}
        />
      </Row>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <Row justify={"start"}>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/location.png")}
          />
          <span style={{ margin: "0px 130px 0px 0px", paddingLeft: 12 }}>
            Geo Fence Match
          </span>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/green-check.png")}
          />
        </Row>
        <br />
        <Row justify={"start"}>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/Age.png")}
          />
          <span style={{ margin: "0px 110px 0px 0px", paddingLeft: 12 }}>
            Over 18 Years of Age
          </span>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/green-check.png")}
          />
        </Row>
        <br />
        <Row
          justify={"start"}
          style={{ paddingBottom: 28, borderBottom: "1px solid grey" }}
        >
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/Coin.png")}
          />
          <span style={{ margin: "0px 100px 0px 0px", paddingLeft: 12 }}>
            Cash Balance Available
          </span>
          <img
            style={{ width: 30, height: 30 }}
            src={require("./images/green-check.png")}
          />
        </Row>
        <br />
        <svg
          width="119"
          height="73"
          viewBox="0 0 129 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: 40, marginLeft: "45%" }}
        >
          <g clip-path="url(#clip0_839_36836)">
            <path
              d="M46.2572 24.2632C41.4768 21.9491 36.6965 20.6868 32.4082 20.6868C26.7139 20.6868 23.1286 23.8425 23.1286 28.611C23.1286 33.1691 26.1515 35.4131 32.0567 35.4131V26.6475H56.8022V56.8012C51.6 57.1518 39.5787 57.6427 36.4856 57.6427C24.1831 57.6427 14.6223 55.5389 8.57657 49.5082C2.81199 43.6878 0 36.6052 0 28.611C0 14.7262 9.06867 0 31.0725 0C40.2114 0 50.6158 2.94525 58.2082 7.50336L46.2572 24.2632Z"
              fill="white"
            />
            <path
              d="M113.323 73H98.1382L90.7567 54.6273L83.9376 73H68.8232L52.4434 26.6475H72.5491L77.8919 41.7944L83.5861 26.6475H97.857L103.762 41.7944L108.894 26.6475H129L113.323 73Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_839_36836">
              <rect width="129" height="73" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <br />
        <span style={{ marginLeft: 30, textAlign: "center" }}>
          GW is offering <strong>$100</strong> Deposited
        </span>
        <br />
        <span style={{ marginLeft: 30, textAlign: "center" }}>
          Immediately if you share addiitional details:
        </span>
        <br />
        <br />
        <Row justify={"start"} style={{ paddingBottom: 10 }}>
          <img
            style={{ width: 25, height: 20 }}
            src={require("./images/email.png")}
          />
          <span style={{ margin: "0px 160px 0px 0px", paddingLeft: 12 }}>
            E-mail Address
          </span>
          <img
            style={{ width: 25, height: 25 }}
            src={require("./images/gray-check.png")}
          />
        </Row>
        <Row justify={"start"} style={{ paddingBottom: 10 }}>
          <img
            style={{ width: 25, height: 20 }}
            src={require("./images/contact.png")}
          />
          <span style={{ margin: "0px 145px 0px 0px", paddingLeft: 12 }}>
            First & Last Name
          </span>
          <img
            style={{ width: 25, height: 25 }}
            src={require("./images/gray-check.png")}
          />
        </Row>
        <br />
        <Row justify={"center"}>
          <Button
            style={{
              backgroundColor: "#1CF2BD",
              color: "#000",
              borderRadius: 16,
              marginBottom: 20,
            }}
            onClick={() => {
              setValidUser(true);
              setOpen(false);
            }}
          >
            Consent and Continue
          </Button>
          <span
            style={{
              color: "#fff",
              textDecoration: "underline",
              marginLeft: 8,
              marginTop: 4,
              cursor: "pointer",
            }}
          >
            Decline & Continue
          </span>
        </Row>
      </div>
    </div>
  );
};

export default Allow;
